import React from 'react';
import { navigate } from 'gatsby-link';
import Layout from '../components/Layout';
import config from '../../config';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const ContactUsPage = () => {
  const [state, setState] = React.useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  return (
    <Layout fullMenu>
      <article id="main">
        <header>
          <h2>Contact Us</h2>
          <p>
            Call us at{' '}
            <a href="tel:{config.phoneNumber}">{config.phoneNumber}</a> for a
            Free Estimate!
          </p>
        </header>
        <section className="wrapper style5">
          <div className="inner">
            <form
              name="contact"
              method="post"
              action="/thanks/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              <div className="row gtr-uniform">
                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                  <label>
                    Don't fill this out:
                    <input name="bot-field" onChange={handleChange} />
                  </label>
                </p>
                <div className="col-6 col-12-xsmall">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    defaultValue=""
                    placeholder="Name"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-6 col-12-xsmall">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    defaultValue=""
                    placeholder="Email"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <select name="type" id="type" onChange={handleChange}>
                    <option value="">- Service Type -</option>
                    <option value="Residential Service">
                      Residential Service
                    </option>
                    <option value="Commercial Service">
                      Commercial Service
                    </option>
                  </select>
                </div>
                <div className="col-6 col-12-small">
                  <input
                    type="checkbox"
                    id="demo-human"
                    name="demo-human"
                    unchecked="true"
                    onChange={handleChange}
                  />
                  <label htmlFor="demo-human">I'm not a robot</label>
                </div>
                <div className="col-12">
                  <textarea
                    name="demo-message"
                    id="demo-message"
                    placeholder="Enter your message"
                    rows="6"
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="col-12">
                  <ul className="actions">
                    <li>
                      <input
                        type="submit"
                        value="Send Message"
                        className="primary"
                      />
                    </li>
                    <li>
                      <input type="reset" value="Reset" />
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default ContactUsPage;
